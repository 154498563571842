import { type CompanyType } from '@finverity/graphql';

import { NavigationMenuPermissions } from '../identity-access';

export enum NavigationSidebarMenuSectionIdentifier {
  Administration = 'ADMINISTRATION',
  CompanyManagement = 'COMPANY_MANAGEMENT',
  Repayments = 'REPAYMENTS',
  FinanceRequestWorkflow = 'FINANCE_REQUEST_WORKFLOW',
  InstrumentUpload = 'INSTRUMENT_UPLOAD',
  PayerApproval = 'PAYER_APPROVAL',
  RequestFinance = 'REQUEST_FINANCE',
  DisbursementAndReconciliation = 'DISBURSEMENT_AND_RECONCILIATION',
  Payments = 'PAYMENTS',
  Home = 'HOME',
}

export enum NavigationSidebarMenuItemIdentifier {
  Dashboard = 'DASHBOARD',
  Companies = 'COMPANIES',
  Products = 'PRODUCTS',
  ProductGroups = 'PRODUCT_GROUPS',
  ProductStructures = 'PRODUCT_STRUCTURES',
  FloatingRates = 'FLOATING_RATES',
  FXRates = 'FX_RATES',
  ProgrammesAndDeals = 'PROGRAMMES_AND_DEALS',
  Programmes = 'PROGRAMMES',
  Deals = 'DEALS',
  CampaignsManagement = 'CAMPAIGNS_MANAGEMENT',
  Notifications = 'NOTIFICATIONS',
  UserManagement = 'USER_MANAGEMENT',
  AuditTrail = 'AUDIT_TRAIL',
  CompanyNotifications = 'COMPANY_NOTIFICATIONS',
  InstrumentUpload = 'INSTRUMENT_UPLOAD',
  InstrumentApproval = 'INSTRUMENT_APPROVAL',
  PayerApprovalMaker = 'PAYER_APPROVAL_MAKER',
  PayerApprovalChecker = 'PAYER_APPROVAL_CHECKER',
  PayerApprovalRejected = 'PAYER_APPROVAL_REJECTED',
  MarkAsPaidWithoutFinancing = 'MARK_AS_PAID_WITHOUT_FINANCING',
  RequestAsSeller = 'REQUEST_AS_SELLER',
  RequestAsPayer = 'REQUEST_AS_PAYER',
  SellerFinanceRequestMaker = 'SELLER_FINANCE_REQUEST_MAKER',
  SellerFinanceRequestChecker = 'SELLER_FINANCE_REQUEST_CHECKER',
  SellerFinanceRequestRejected = 'SELLER_FINANCE_REQUEST_REJECTED',
  PayerFinanceRequestMaker = 'PAYER_FINANCE_REQUEST_MAKER',
  PayerFinanceRequestChecker = 'PAYER_FINANCE_REQUEST_CHECKER',
  PayerFinanceRequestRejected = 'PAYER_FINANCE_REQUEST_REJECTED',
  DisbursementAndReconciliation = 'DISBURSEMENT_AND_RECONCILIATION',
  ReadyForFunding = 'READY_FOR_FUNDING',
  CompanySsoSettings = 'COMPANY_SSO_SETTINGS',
  CustomerCompanyDetails = 'CUSTOMER_COMPANY_DETAILS',

  IncomingPayments = 'INCOMING_PAYMENTS',

  Disbursements = 'DISBURSEMENTS',
  Repayments = 'REPAYMENTS',
  DisbursementConfirmation = 'DISBURSEMENT_CONFIRMATION',
  DisbursementFiles = 'DISBURSEMENT_FILES',
  FinanceApproval = 'FINANCE_APPROVAL',
  FunderApprovalMaker = 'FUNDER_APPROVAL_MAKER',
  FunderApprovalChecker = 'FUNDER_APPROVAL_CHECKER',
  FunderApprovalRejected = 'FUNDER_APPROVAL_REJECTED',
  RejectedByFunder = 'REJECTED_BY_FUNDER',
  OutstandingInstruments = 'OUTSTANDING_INSTRUMENTS',
  Changelog = 'CHANGELOG',
  Help = 'HELP',
}

export interface NavigationSidebarMenuSection {
  readonly sectionIdentifier: NavigationSidebarMenuSectionIdentifier;
  readonly title: string;
  readonly menuItems: readonly (NavigationSidebarMenuGroup | NavigationSidebarMenuItem)[];
}

export interface NavigationSidebarMenuItemBase {
  readonly identifier: NavigationSidebarMenuItemIdentifier;
  readonly title: string;
  readonly disabled: boolean;
  // add for case from https://finverity.atlassian.net/browse/FDS-988 story
  // WHEN the user clicks on the Company Notifications menu in the sidebar
  // THEN redirect the user to the Company Notification Settings page (/company-notifications)
  readonly titleForHeader?: string;
  //
  readonly iconName?: string;
  readonly permission?: NavigationMenuPermissions;
  readonly companyTypes?: CompanyType[];
  readonly matcher?: (routeUrl: string) => boolean;
}

export interface NavigationSidebarMenuItem extends NavigationSidebarMenuItemBase {
  readonly route: string;
}

export interface NavigationSidebarMenuGroup extends NavigationSidebarMenuItemBase {
  readonly subMenuItems: readonly NavigationSidebarMenuItem[];
}

export interface NavigationSidebarMenuBottomSectionItem extends NavigationSidebarMenuItemBase {
  readonly href: string;
}
